import PropTypes from 'prop-types'; // @mui
import Button from '@mui/material/Button';
// routes
// import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// auth

// ----------------------------------------------------------------------

// const loginPaths = {
//   jwt: paths.auth.jwt.login,
//   auth0: paths.auth.auth0.login,
//   amplify: paths.auth.amplify.login,
//   firebase: paths.auth.firebase.login,
// };

export default function LoginButton({ sx }) {
  // const { method } = useAuthContext();

  // const loginPath = loginPaths['method'];

  return (
    <Button component={RouterLink} variant="outlined" sx={{ mr: 1, ...sx }}>
      Login
    </Button>
  );
}

LoginButton.propTypes = {
  sx: PropTypes.object,
};
