import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const apiService = axios.create({
  baseURL: API_BASE_URL,
  timeout: 5000,
});

export const getCountries = () => apiService.get("/countries");
export const postCountries = (data) => apiService.post("/countries", data);
export const updateCountries = (country_id, data) =>
  apiService.put(`/countries/${country_id}`, data);

export { API_BASE_URL };
