import { useSettingsContext } from '../context';
import BaseOptions from './base-option';

export default function Mode() {
  const settings = useSettingsContext();
  // light dark

  const renderMode =
    settings.themeMode === 'light' ? (
      <BaseOptions
        value={settings.themeMode}
        onChange={(newValue) => settings.onUpdate('themeMode', newValue)}
        options={['dark']}
        icons={['moon']}
        sx={{ height: 40, width: 40, borderRadius: 10 }}
        spacing={1}
      />
    ) : (
      <BaseOptions
        value={settings.themeMode}
        onChange={(newValue) => settings.onUpdate('themeMode', newValue)}
        options={['light']}
        icons={['sun']}
        sx={{ height: 40, width: 40, borderRadius: 10 }}
        spacing={1}
      />
    );

  //   <BaseOptions
  //   value={settings.themeMode}
  //   onChange={(newValue) => settings.onUpdate('themeMode', newValue)}
  //   options={['light', 'dark']}
  //   icons={['sun', 'moon']}
  //   sx={{ height: 40, width: 40, borderRadius: 10 }}
  //   spacing={1}
  // />

  return <>{renderMode}</>;
}
