import {
  Button,
  Drawer,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
// import { useContentActions } from 'src/context/providers/ContentContext';
import CloseIcon from "@mui/icons-material/Close";
// import { useContentActions } from 'src/context/providers/ContenteContext';
import { useState } from "react";
import { personalInformation } from "./view/data";
import InputFieds from "../../custom_components/Froms/InputFields";
import { Box } from "@mui/system";
import { useContentActions } from "src/context/providers/ContentContext";

const CreateContentDrawer = ({ isDrawerOpen, handleDrawerToggle }) => {
  const [ContentData, setContentData] = useState({
    translations: {},
  });
  const [newTranslation, setNewTranslation] = useState({
    language: "",
    text: "",
  });

  const { createContentItem } = useContentActions();

  const Clear = () => {
    setContentData({
      translations: {},
    });
  };

  const handleCreateContent = async () => {
    await createContentItem(ContentData);
    Clear();
    handleDrawerToggle();
  };
  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setContentData({
  //     ...ContentData,
  //     [name]: value,
  //   });
  // };
  // const airwayBills = useAirwayBillData();

  const DrawerConfig = {
    SubNavigation: true,
    anchor: "right",
    width: "35%",
    drawerHeaderText: "Create Content",
    buttons: {
      status: true,
      elements: [{ name: "Create", onClick: handleCreateContent }],
    },
  };

  const containerStyle = {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    position: "fixed",
    zIndex: 2,
    top: 0,
    left: 0,
    display: isDrawerOpen ? "block" : "none",
    cursor: "pointer",
  };

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      handleDrawerToggle();
    }
  };

  /// translation
  const handleTranslationChange = (event) => {
    const { name, value } = event.target;
    setNewTranslation((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addTranslation = () => {
    if (!newTranslation.language || !newTranslation.text) return; // Ensure non-empty inputs
    setContentData((prev) => ({
      ...prev,
      translations: {
        ...prev.translations,
        [newTranslation.language]: newTranslation.text,
      },
    }));
    setNewTranslation({ language: "", text: "" }); // Reset form
  };

  return (
    <div
      role="button"
      tabIndex={0}
      style={containerStyle}
      onKeyDown={(event) => {
        if (event.key === "Escape") {
          handleDrawerToggle();
        }
      }}
      onClick={handleOutsideClick}
    >
      <Drawer
        variant="persistent"
        anchor={DrawerConfig.anchor}
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
        PaperProps={{
          sx: { width: { lg: DrawerConfig.width, xs: "100%", md: "100%" } },
        }}
      >
        {/* Drawer Content */}

        {/* Header */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px",
            borderBottom: "1px solid rgba(220, 220, 220, 0.2)",
          }}
        >
          <Typography variant="h6">{DrawerConfig.drawerHeaderText}</Typography>

          <IconButton onClick={handleDrawerToggle}>
            <CloseIcon />
          </IconButton>
        </div>

        {/* Form */}
        <div
          className="content-wrap"
          style={{
            gap: "2px",
            padding: "30px 0px 0px 0px",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          {/* Form */}

          {/* Personal Information */}
          <InputFieds
            userData={ContentData}
            heading="Content Information"
            inputFields={personalInformation}
            setUserData={setContentData}
          />

          <Box sx={{ padding: "0px 20px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <TextField
                label="Language"
                variant="outlined"
                name="language"
                value={newTranslation.language}
                onChange={handleTranslationChange}
                margin="normal"
              />
              <TextField
                label="Translation"
                variant="outlined"
                name="text"
                value={newTranslation.text}
                onChange={handleTranslationChange}
                margin="normal"
              />
              <Button onClick={addTranslation} variant="contained">
                Add
              </Button>
            </Box>
            <div>
              <ul>
                {Object.entries(ContentData?.translations)?.map(
                  ([lang, text], index) => (
                    <li key={index}>{`${lang}: ${text}`}</li>
                  )
                )}
              </ul>
            </div>
          </Box>
        </div>

        {/* Buttons */}
        {DrawerConfig.buttons && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              borderTop: "1px solid rgba(220, 220, 220, 0.2)",
              padding: "16px",
              position: "absolute",
              bottom: "0",
              left: "0",
              width: "100%",
            }}
          >
            {DrawerConfig.buttons.elements.map((element, index) => (
              <Button
                key={index}
                variant="contained"
                style={{
                  marginLeft: "24px",
                  width: "136px",
                }}
                onClick={element.onClick}
              >
                Create
              </Button>
            ))}
          </div>
        )}
      </Drawer>
    </div>
  );
};

CreateContentDrawer.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
};

export default CreateContentDrawer;
