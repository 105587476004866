const TABLE_HEAD = [
  { id: "key", label: "Key" },
  { id: "translations", label: "translations" },
  { id: "version", label: "Version" },
];

const defaultFilters = {
  name: "",
  role: [],
  tracking: "tracking",
  status: "all",
};
const tableNames = [
  { id: "key", label: "Key" },
  { id: "translations", label: "translations" },
  { id: "version", label: "Version" },
];
const personalInformation = [
  {
    name: "key",
    width: "97%",
    label: "Content Key",
  },
];

const status_colors = {
  CREATED: ["#22C55E29", "#118D57"],
  SHIPPED: ["#919EAB29", "#637381"],
  DELIVERED: ["#FFAB0029", "#B76E00"],
  CANCELLED: ["#FF563029", "#B71D18"],
};

const status = ["CREATED", "SHIPPED", "DELIVERED", "CANCELLED"];

export {
  TABLE_HEAD,
  defaultFilters,
  tableNames,
  status,
  personalInformation,
  status_colors,
};
