// CustomerContext.js
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { redirect } from "react-router";
import PropTypes from "prop-types"; // @mui
import { apiService } from "src/utils/apiServices";
import { toast } from "react-toastify";

const CustomerContext = createContext();
const CustomerActionsContext = createContext();

export const CustomerProvider = ({ children }) => {
  const [customers, setCustomers] = useState([]);

  const createCustomer = async (newCustomer) => {
    try {
      // Simulate API call to create customer
      const response = await apiService.post("/customers", newCustomer, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      const data = await response.data;
      setCustomers((prevCustomers) => [...prevCustomers, data]);
      toast.success("customer created!");
    } catch (error) {
      toast.error("Error creating customer!");
      console.error("Error creating customer:", error);
    }
  };

  const deleteCustomer = async (customerId) => {
    try {
      await apiService.delete(`/users?ids=${customerId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      setCustomers((prevCustomers) =>
        prevCustomers.filter((customer) => !customerId.includes(customer.id))
      );
      toast.success("customer deleted!");
    } catch (error) {
      toast.error("Error deleting customer");
      console.error("Error deleting customer:", error);
    }
  };

  const updateCustomer = async (customerId, updatedCustomer) => {
    try {
      // Simulate API call to update customer
      const response = await apiService.patch(
        `/users/${customerId}`,
        updatedCustomer,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.data;
      setCustomers((prevCustomers) =>
        prevCustomers.map((customer) =>
          customer.id === customerId ? { ...customer, ...data } : customer
        )
      );
      toast.success("customer updated!");
    } catch (error) {
      toast.error("Error updating customer");
      console.error("Error updating customer:", error);
    }
  };

  const getCustomers = async () => {
    try {
      // Simulate API call to get customers
      const response = await apiService.get(`/users`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      const data = await response.data;
      setCustomers(data);
    } catch (error) {
      console.error("Error fetching customers:", error);
      if (error.response.status === 401) {
        sessionStorage.removeItem("token");
        redirect("/");
      }
    }
  };

  useEffect(() => {
    getCustomers();
  }, []);

  const createCustomerCallback = useCallback(createCustomer, []);
  const deleteCustomerCallback = useCallback(deleteCustomer, []);
  const updateCustomerCallback = useCallback(updateCustomer, [updateCustomer]);
  const getCustomersCallback = useCallback(getCustomers, []);

  const customerActionsValue = useMemo(
    () => ({
      createCustomer: createCustomerCallback,
      deleteCustomer: deleteCustomerCallback,
      updateCustomer: updateCustomerCallback,
      getCustomers: getCustomersCallback,
    }),
    [
      createCustomerCallback,
      deleteCustomerCallback,
      updateCustomerCallback,
      getCustomersCallback,
    ]
  );

  const customersValue = useMemo(() => customers, [customers]);

  return (
    <CustomerContext.Provider value={customersValue}>
      <CustomerActionsContext.Provider value={customerActionsValue}>
        {children}
      </CustomerActionsContext.Provider>
    </CustomerContext.Provider>
  );
};

export const useCustomerData = () => useContext(CustomerContext);
export const useCustomerActions = () => useContext(CustomerActionsContext);

CustomerProvider.propTypes = {
  children: PropTypes.element,
};
