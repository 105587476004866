import {
  Button,
  Drawer,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import InputFields from "src/custom_components/Froms/InputFields";
import { useState } from "react";
// import { useblogActions } from 'src/context/providers/blogContext';
import { personalInformation } from "./view/data";
import { Box } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import { API_BASE_URL } from "src/utils/apiServices";
import { useBlogActions } from "src/context/providers/BlogsContext";
import UploadImage from "src/custom_components/Froms/imageUpload";
import { MultiFilePreview } from "src/components/upload";

const CustomerDrawerComponentblog = ({
  isDrawerOpen,
  handleDrawerToggle,
  aRow,
  setARow,
}) => {
  const [blogData, setblogData] = useState({
    id: aRow.id,
    title: aRow.title,
    content: aRow.content,
    publicationDate: aRow?.publicationDate || null,
    author: aRow.author,
    tags: aRow.tags,
    keywords: aRow?.meta?.keywords,
    description: aRow?.meta?.description,
    imageLinks: aRow.imageLinks,
    videoLinks: aRow.videoLinks,
    views: aRow.views.toString(),
    likes: aRow.likes.toString(),
    shares: aRow.shares.toString(),
  });

  const [files, setFiles] = useState([]);
  const [videoFile, setVideoFile] = useState([]);

  const [deletedImages, setDeletedImages] = useState([]);

  const [newTag, setNewTag] = useState("");

  const { updateBlogPost, sendImage, sendVideoFiles } = useBlogActions();
  const Clear = () => {
    setblogData({});
    setNewTag("");
  };
  console.log(aRow.images);
  console.log(aRow.videos);

  const handleUpdate = async () => {
    const data = {
      id: blogData.id,
      title: blogData.title,
      content: blogData.content,
      publicationDate: blogData.publicationDate,
      author: blogData.author,
      tags: blogData.tags,
      meta: {
        description: blogData.description,
        keywords: blogData.keywords,
      },
    };
    await updateBlogPost(aRow.id, data);
    handleDrawerToggle();
    setARow({});
  };

  const DrawerConfig = {
    SubNavigation: true,
    anchor: "right",
    width: "40%",
    drawerHeaderText: `Edit blog - #${aRow.id}`,
    buttons: {
      status: true,
      elements: [{ name: "Update", onClick: handleUpdate, disabled: false }],
    },
  };

  const containerStyle = {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    position: "fixed",
    zIndex: 2,
    top: 0,
    left: 0,
    display: isDrawerOpen ? "block" : "none",
    cursor: "pointer",
  };

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      handleDrawerToggle();
      Clear();
      setARow({});
    }
  };
  // eslint-disable-next-line
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setblogData({
      ...blogData,
      [name]: value,
    });
  };
  const handleDateChange = (date) => {
    // Function to handle date change
    setblogData({
      ...blogData,
      publicationDate: date,
    });
  };

  //tags
  const handleTagChange = (event) => {
    setNewTag(event.target.value);
  };

  const addTag = () => {
    if (!newTag) return; // Ensure non-empty tag
    setblogData((prev) => ({
      ...prev,
      tags: [...prev.tags, newTag],
    }));
    setNewTag(""); // Reset new tag input
  };
  const addFiles = (e) => {
    e.preventDefault();
    setFiles((prevFileData) => [...prevFileData, files]);
    if (files) {
      sendImage(files, aRow.id);
      setFiles([]);
    }
  };

  const addVideo = (e) => {
    e.preventDefault();
    setVideoFile((prevFileData) => [...prevFileData, files]);
    if (videoFile) {
      sendVideoFiles(videoFile, aRow.id);
      setVideoFile([]);
    }
  };
  const renderImageUpload = (
    <Box sx={{ borderTop: "1px dashed grey", bordeRadius: "20px" }}>
      <Typography
        gutterBottom
        className="subTitles"
        sx={{ padding: "20px 30px 0px " }}
      >
        Upload Image
      </Typography>
      <form
        onSubmit={(e) => addFiles(e)}
        style={{
          display: "flex",
          alignItems: "center",
          padding: " 0px 30px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <UploadImage
            row={blogData}
            files={files}
            setFiles={setFiles}
            deletedImages={deletedImages}
            setDeletedImages={setDeletedImages}
            hasPadding={false}
          />
        </div>
        <Button
          variant="contained"
          style={{
            marginLeft: "24px",
            width: "136px",
          }}
          type="submit"
        >
          ADD
        </Button>
      </form>
      {aRow.images.length
        ? aRow.images.map((i) => (
            <MultiFilePreview
              thumbnail
              files={[`${API_BASE_URL}/file/get/images/${i.name}`]}
              // onRemove={(file) => handleRemoveFile(file)}
              sx={{ width: 100, height: 100, marginLeft: "20px" }}
            />
          ))
        : ""}
    </Box>
  );

  const renderVideoUpload = (
    <Box
      sx={{
        borderTop: "1px dashed grey",
        bordeRadius: "20px",
        marginTop: "40px",
      }}
    >
      <Typography
        gutterBottom
        className="subTitles"
        sx={{ padding: "20px 30px 0px " }}
      >
        Upload Video
      </Typography>
      <form
        onSubmit={(e) => addVideo(e)}
        style={{
          display: "flex",
          alignItems: "center",
          padding: " 0px 30px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <UploadImage
            row={blogData}
            files={videoFile}
            setFiles={setVideoFile}
            deletedImages={deletedImages}
            setDeletedImages={setDeletedImages}
            hasPadding={false}
          />
        </div>
        <Button
          variant="contained"
          style={{
            marginLeft: "24px",
            width: "136px",
          }}
          type="submit"
        >
          ADD
        </Button>
      </form>

      {aRow.videos.length
        ? aRow.videos.map((i) => (
            <MultiFilePreview
              thumbnail
              files={[`${API_BASE_URL}/file/get/videos/${i.name}`]}
              // onRemove={(file) => handleRemoveFile(file)}
              sx={{ width: 100, height: 100, marginLeft: "20px" }}
            />
          ))
        : ""}
    </Box>
  );
  return (
    <div
      role="button"
      tabIndex={0}
      style={containerStyle}
      onKeyDown={(event) => {
        if (event.key === "Escape") {
          handleDrawerToggle();
          Clear();
          setARow({});
        }
      }}
      onClick={handleOutsideClick}
    >
      <Drawer
        variant="persistent"
        anchor={DrawerConfig.anchor}
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
        PaperProps={{
          sx: { width: { lg: DrawerConfig.width, xs: "100%", md: "100%" } },
        }}
      >
        {/* Drawer Content */}

        {/* Header */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px",
            borderBottom: "1px solid rgba(220, 220, 220, 0.2)",
          }}
        >
          <Typography variant="h6">{DrawerConfig.drawerHeaderText}</Typography>

          <IconButton onClick={handleDrawerToggle}>
            <CloseIcon />
          </IconButton>
        </div>

        {/* Form */}

        <div
          className="content-wrap"
          style={{
            gap: "2px",
            padding: "30px 0px 0px 0px",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          {/* Form */}
          {/* Personal Information */}
          <InputFields
            userData={blogData}
            heading="Blogs Information"
            inputFields={personalInformation}
            setUserData={setblogData}
          />
          {/* Personal Information */}

          <DatePicker
            sx={{ margin: "0px 20px", width: "90%" }}
            label="Publication Date"
            value={
              blogData?.publicationDate
                ? new Date(blogData.publicationDate)
                : null
            }
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} />}
          />

          <Box sx={{ padding: "0px 20px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <TextField
                label="Tag"
                variant="outlined"
                value={newTag}
                onChange={handleTagChange}
                margin="normal"
              />
              <Button onClick={addTag} variant="contained">
                Add Tag
              </Button>
            </Box>
            <div>
              <ul>
                {blogData.tags.map((tag, index) => (
                  <li key={index}>{tag}</li>
                ))}
              </ul>
            </div>
          </Box>

          {renderImageUpload}

          {renderVideoUpload}
        </div>
        {/* Buttons */}
        {DrawerConfig.buttons && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              borderTop: "1px solid rgba(220, 220, 220, 0.2)",
              padding: "16px",
              bottom: "0",
              left: "0",
              width: "100%",
            }}
          >
            {DrawerConfig.buttons.elements.map((element) => (
              <Button
                variant="contained"
                style={{
                  marginLeft: "24px",
                  width: "136px",
                }}
                disabled={element.disabled}
                onClick={element.onClick}
              >
                {element.name}
              </Button>
            ))}
          </div>
        )}
      </Drawer>
    </div>
  );
};

CustomerDrawerComponentblog.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  aRow: PropTypes.object.isRequired,
  setARow: PropTypes.func.isRequired,
};

export default CustomerDrawerComponentblog;
