import { USER_STATUS_OPTIONS } from "src/_mock";

const STATUS_OPTIONS = [{ value: "all", label: "All" }, ...USER_STATUS_OPTIONS];

const TABLE_HEAD = [
  { id: "name", label: "First Name", width: 300 },
  { id: "last_name", label: "Last Name", width: 300 },
  { id: "dateOfBirth", label: "Date Of Birth", width: 300 },
  { id: "phone", label: "Phone", width: 300 },
  { id: "email", label: "Email", width: 300 },
  { id: "isAdmin", label: "Admin", width: 100 },
];

const tableNames = [
  { id: "name", label: "First Name" },
  { id: "last_name", label: "Last Name" },
  { id: "dateOfBirth", label: "Date Of Birth" },
  { id: "phone", label: "Phone" },
  { id: "email", label: "Email" },
  { id: "isAdmin", label: "Admin", width: 100 },
];
const defaultFilters = {
  name: "",
  first_name: "",
  last_name: "",
  id_number: "",
  email: "",
  phone: "",
  role: [],
  status: "all",
};

const personalInformation = [
  {
    name: "name",
    width: "48%",
    label: "First Name",
  },
  {
    name: "last_name",
    width: "48%",
    label: "Last Name",
  },
  {
    name: "phone",
    width: "48%",
    label: "Phone",
  },
  {
    name: "email",
    width: "48%",
    label: "Email",
  },
];

export {
  STATUS_OPTIONS,
  TABLE_HEAD,
  defaultFilters,
  tableNames,
  personalInformation,
};
