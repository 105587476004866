const TABLE_HEAD = [
  { id: "title", label: "Title", width: 320 },
  { id: "content", label: "Content", width: 380 },
  { id: "publicationDate", label: "Publication Date", width: 380 },
  { id: "author", label: "Author", width: 320 },
  { id: "views", label: "Views", width: 100 },
  { id: "likes", label: "Likes", width: 100 },
  { id: "shares", label: "Shares", width: 100 },
];

const defaultFilters = {
  name: "",
  country: "country",
  currency: "currency",
  role: [],
  status: "all",
};
const tableNames = [
  { id: "title", label: "Title" },
  { id: "content", label: "Content" },
  { id: "publicationDate", label: "Publication Date" },
  { id: "author", label: "Author" },
  { id: "views", label: "Views" },
  { id: "likes", label: "Likes" },
  { id: "shares", label: "Shares" },
];

const personalInformation = [
  {
    name: "title",
    width: "48%",
    label: "Title",
  },
  {
    name: "content",
    width: "48%",
    label: "Content",
  },
  {
    name: "author",
    width: "96%",
    label: "Author",
  },
  {
    name: "description",
    width: "96%",
    label: "Description  (Meta)",
  },
  {
    name: "keywords",
    width: "96%",
    label: "Keywords (Meta)",
  },
  {
    name: "views",
    width: "48%",
    label: "Views",
    type: "number",
    disabled: true,
  },
  {
    name: "likes",
    width: "48%",
    label: "Likes",
    type: "number",
    disabled: true,
  },
  {
    name: "shares",
    width: "96%",
    label: "Shares",
    type: "number",
    disabled: true,
  },
];

const personalInformationCreate = [
  {
    name: "title",
    width: "48%",
    label: "Title",
  },
  {
    name: "content",
    width: "48%",
    label: "Content",
  },
  {
    name: "author",
    width: "96%",
    label: "Author",
  },

  {
    name: "description",
    width: "96%",
    label: "Description  (Meta)",
  },
  {
    name: "keywords",
    width: "96%",
    label: "Keywords (Meta)",
  },
];

const status_colors = {
  ACTIVE: ["#22C55E29", "#118D57"],
  INACTIVE: ["#919EAB29", "#637381"],
};

const status = ["ACTIVE", "INACTIVE"];

export {
  TABLE_HEAD,
  defaultFilters,
  tableNames,
  status,
  personalInformation,
  status_colors,
  personalInformationCreate,
};
