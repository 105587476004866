import {
  Button,
  Drawer,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import InputFields from "src/custom_components/Froms/InputFields";
import { useState } from "react";
// import { useCountryActions } from 'src/context/providers/CountryContext';
import { personalInformationCreate } from "./view/data";
import { useBlogActions } from "src/context/providers/BlogsContext";
import { DatePicker } from "@mui/x-date-pickers";
import { Box } from "@mui/system";

const CreateDrawerBlog = ({ isDrawerOpen, handleDrawerToggle }) => {
  const [blogsData, setBlogsData] = useState({
    title: "",
    content: "",
    publicationDate: null,
    author: "",
    description: "",
    keywords: "",
    tags: [],
  });

  const [newTag, setNewTag] = useState(""); // State for the new tag input

  const { createBlogPost } = useBlogActions();

  const Clear = () => {
    setBlogsData({
      title: "",
      content: "",
      publicationDate: null,
      author: "",
      description: "",
      keywords: "",
      tags: [],
    });
    setNewTag("");
  };

  const handleCreateBlog = () => {
    const blogsDataToSend = {
      title: blogsData.title,
      content: blogsData.content,
      publicationDate: blogsData.publicationDate,
      author: blogsData.author,
      meta: {
        description: blogsData.description,
        keywords: blogsData.keywords,
      },
      tags: blogsData.tags,
    };
    createBlogPost(blogsDataToSend);
    Clear();
    handleDrawerToggle();
  };

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setBlogsData({
  //     ...blogsData,
  //     [name]: value,
  //   });
  // };
  const DrawerConfig = {
    SubNavigation: true,
    anchor: "right",
    width: "35%",
    drawerHeaderText: "Add New Blog",
    buttons: {
      status: true,
      elements: [{ name: "Create", onClick: handleCreateBlog }],
    },
  };
  const containerStyle = {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    position: "fixed",
    zIndex: 2,
    top: 0,
    left: 0,
    display: isDrawerOpen ? "block" : "none",
    cursor: "pointer",
  };

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      handleDrawerToggle();
    }
  };
  const handleDateChange = (date) => {
    // Function to handle date change
    setBlogsData({
      ...blogsData,
      publicationDate: date,
    });
  };

  //tags
  const handleTagChange = (event) => {
    setNewTag(event.target.value);
  };

  const addTag = () => {
    if (!newTag) return; // Ensure non-empty tag
    setBlogsData((prev) => ({
      ...prev,
      tags: [...prev.tags, newTag],
    }));
    setNewTag(""); // Reset new tag input
  };
  return (
    <div
      role="button"
      tabIndex={0}
      style={containerStyle}
      onKeyDown={(event) => {
        if (event.key === "Escape") {
          handleDrawerToggle();
        }
      }}
      onClick={handleOutsideClick}
    >
      <Drawer
        variant="persistent"
        anchor={DrawerConfig.anchor}
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
        PaperProps={{
          sx: { width: { lg: DrawerConfig.width, xs: "100%", md: "100%" } },
        }}
      >
        {/* Drawer Content */}

        {/* Header */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px",
            borderBottom: "1px solid rgba(220, 220, 220, 0.2)",
          }}
        >
          <Typography variant="h6">{DrawerConfig.drawerHeaderText}</Typography>

          <IconButton onClick={handleDrawerToggle}>
            <CloseIcon />
          </IconButton>
        </div>

        {/* Form */}
        <div
          className="content-wrap"
          style={{
            gap: "2px",
            padding: "30px 0px 0px 0px",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          {/* Form */}

          {/* Personal Information */}
          <InputFields
            userData={blogsData}
            heading="blog Information"
            inputFields={personalInformationCreate}
            setUserData={setBlogsData}
          />
          {/* Personal Information */}

          <DatePicker
            sx={{ margin: "0px 20px", width: "90%" }}
            label="Publication Date"
            value={blogsData.publicationDate}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} />}
          />

          <Box sx={{ padding: "0px 20px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <TextField
                label="Tag"
                variant="outlined"
                value={newTag}
                onChange={handleTagChange}
                margin="normal"
              />
              <Button onClick={addTag} variant="contained">
                Add Tag
              </Button>
            </Box>
            <div>
              <ul>
                {blogsData.tags.map((tag, index) => (
                  <li key={index}>{tag}</li>
                ))}
              </ul>
            </div>
          </Box>
        </div>

        {/* Buttons */}
        {DrawerConfig.buttons && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              borderTop: "1px solid rgba(220, 220, 220, 0.2)",
              padding: "16px",
              position: "absolute",
              bottom: "0",
              left: "0",
              width: "100%",
            }}
          >
            {DrawerConfig.buttons.elements.map((element) => (
              <Button
                variant="contained"
                style={{
                  marginLeft: "24px",
                  width: "136px",
                }}
                onClick={element.onClick}
              >
                Create
              </Button>
            ))}
          </div>
        )}
      </Drawer>
    </div>
  );
};

CreateDrawerBlog.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
};

export default CreateDrawerBlog;
