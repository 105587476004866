import React, { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { apiService } from 'src/utils/apiServices';

const AuthContext = createContext();
const AuthActionsContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);

  const authActionsValue = useMemo(() => {

    const signIn = async (signInData) => {
      try {
        const response = await apiService.post('/auth/signin', signInData);
        const data = await response.data.accessToken;
        sessionStorage.setItem('token', JSON.stringify(data));
      } catch (error) {
        console.error('Error signing in:', error);
        throw error;
      }
    };
    
    const register = async (registerData) => {
      try {
        const response = await apiService.post('/auth/signup', registerData);
        const data = await response.data.accessToken;
        sessionStorage.setItem('token', JSON.stringify(data));
      } catch (error) {
        console.error('Error signing in:', error);
        throw error;
      }
    };
    

    const signOut = () => {
      setToken(null);
      sessionStorage.removeItem('token');
    };

    // Add other authentication-related functions as needed

    return {
      signIn,
      signOut,
      register
      // Add other authentication-related functions here
    };
  }, []);

  const authValue = useMemo(() => token, [token]);

  return (
    <AuthContext.Provider value={authValue}>
      <AuthActionsContext.Provider value={authActionsValue}>{children}</AuthActionsContext.Provider>
    </AuthContext.Provider>
  );
};

export const useAuthData = () => useContext(AuthContext);
export const useAuthActions = () => useContext(AuthActionsContext);

AuthProvider.propTypes = {
  children: PropTypes.element,
};
