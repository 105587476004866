// @mui
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';

// theme
import { bgBlur } from 'src/theme/css';

// config
import { PATH_AFTER_LOGOUT, PATH_CONTENT_MANAGEMENT } from 'src/config-global';

// contrast
import Mode from 'src/components/settings/drawer/mode';

// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';
import { jwtDecode } from 'jwt-decode';
//
import { useAuthActions } from '../../context/Auth/AuthContext';
import { HEADER, NAV } from '../config-layout';
// import { SettingsButton } from '../_common';

// ----------------------------------------------------------------------

export default function Header({ onOpenNav }) {
  const { signOut } = useAuthActions();
  const theme = useTheme();

  const settings = useSettingsContext();

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;
  const navigate = useNavigate();

  function LogOut() {
    signOut();
    window.location.href = PATH_AFTER_LOGOUT;
  }

  function ContentUpdate() {
    navigate(PATH_CONTENT_MANAGEMENT.root);
  }

  const auth = JSON.parse(sessionStorage.getItem('token'));
  let decoded = '';
  if (auth) {
    decoded = jwtDecode(auth);
  }

  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 4 }}
      >
        {/* <SettingsButton /> */}
        <Mode />
        <Button variant="contained" color="primary" onClick={() => LogOut()}>
          Sign Out
        </Button>
        {decoded.location === 'Georgia' ? (
          <Button variant="outlined" color="primary" onClick={() => ContentUpdate()}>
            Content Management
          </Button>
        ) : (
          ''
        )}
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
