import { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import DashboardLayout from "src/layouts/dashboard";
import { LoadingScreen } from "src/components/loading-screen";
import CustomerListPage from "src/pages/dashboard/customer/list";
import ContentListView from "src/sections/content/view/pallete-list-view";

// import { jwtDecode } from "jwt-decode";
import {
  CustomerProvider,
  ContentProvider,
  BlogProvider,
} from "../../context/providers";
import { BlogsListView } from "src/sections/blogs/view";

const auth = JSON.parse(sessionStorage.getItem("token"));

// Helper function to wrap providers
function wrapProviders(component) {
  return (
    <CustomerProvider>
      <BlogProvider>
        <ContentProvider>{component}</ContentProvider>
      </BlogProvider>
    </CustomerProvider>
  );
}

let routChildren = [];

routChildren = [
  { element: <Navigate to="customer" />, index: true },
  {
    path: "customer",
    children: [
      { element: <CustomerListPage />, index: true },
      { path: ":customer_id", element: <CustomerListPage /> },
    ],
  },
  {
    path: "content",
    children: [{ element: <ContentListView />, index: true }],
  },
  {
    path: "blogs",
    children: [{ element: <BlogsListView />, index: true }],
  },
];

export const dashboardRoutes = auth
  ? [
      {
        path: "dashboard",
        element: (
          <DashboardLayout>
            {wrapProviders(
              <Suspense fallback={<LoadingScreen />}>
                <Outlet />
              </Suspense>
            )}
          </DashboardLayout>
        ),
        children: routChildren,
      },
    ]
  : [];
