import isEqual from "lodash/isEqual";
import { useState, useMemo, useCallback } from "react";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Tab,
  Tabs,
  Card,
  Table,
  Button,
  Container,
  TableBody,
  LinearProgress,
  TableContainer,
  Tooltip,
  IconButton,
} from "@mui/material";
// routes
import { paths } from "src/routes/paths";
import { useRouter } from "src/routes/hook";
import { useParams } from "react-router-dom";
// _mock
import { _roles } from "src/_mock";
// hooks
// components
import Label from "src/components/label";
import Scrollbar from "src/components/scrollbar";
import { useSettingsContext } from "src/components/settings";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
  TableSelectedAction,
} from "src/components/table";
//
import {
  useCustomerActions,
  useCustomerData,
} from "src/context/providers/CustomerContext";
//
import { getColorForStatus } from "src/custom_components/Table/helper/getColorStatus";
import { applyFilter } from "src/custom_components/Table/helper/applyFilter";
import PropTypes from "prop-types";
import UserTableRow from "../../custom_components/Table/customer-table-row";
import UserTableToolbar from "../../custom_components/Table/customer-table-toolbar";
import UserTableFiltersResult from "../../custom_components/Table/customer-table-filters-result";
import CustomerDrawerComponent from "./customer-drawer-component";
import Iconify from "src/components/iconify";
import { ConfirmDialog } from "src/components/custom-dialog";
import { useBoolean } from "src/hooks/use-boolean";

export default function TcTable({
  STATUS_OPTIONS,
  TABLE_HEAD,
  defaultFilters,
  tableNames,
}) {
  const table = useTable();
  const settings = useSettingsContext();
  const router = useRouter();
  const tableData = useCustomerData();
  const [aRow, setARow] = useState({});
  const confirm = useBoolean();

  const { deleteCustomer } = useCustomerActions();

  const { customer_id } = useParams();

  const [filters, setFilters] = useState(defaultFilters);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.user.edit(id));
    },
    [router]
  );

  const handleDeleteRow = useCallback(
    (e, id) => {
      e.stopPropagation();
      deleteCustomer([id]);
      table.onUpdatePageDeleteRow(dataInPage.length);
    },
    // eslint-disable-next-line
    [dataInPage.length, table]
  );

  const handleDeleteRows = useCallback(() => {
    deleteCustomer(table.selected);
    // eslint-disable-next-line
  }, [table]);

  const handleFilterStatus = useCallback(
    (event, newValue) => {
      handleFilters("status", newValue);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  // Custom
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  useMemo(() => {
    const rowFromRouter = tableData.filter((item) => item.id === customer_id);
    if (rowFromRouter.length) {
      setARow(rowFromRouter[0]);
      setIsDrawerOpen(true);
    }
  }, [customer_id, tableData]);

  return (
    <div>
      {!tableData.length ? <LinearProgress color="success" /> : ""}{" "}
      <Container maxWidth={settings.themeStretch ? false : "lg"}>
        {Object.keys(aRow).length > 0 && (
          <CustomerDrawerComponent
            isDrawerOpen={isDrawerOpen}
            handleDrawerToggle={handleDrawerToggle}
            aRow={aRow}
            setARow={setARow}
          />
        )}
        <CustomBreadcrumbs
          heading="Customers List"
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Card>
          <Tabs
            value={filters.status}
            onChange={handleFilterStatus}
            style={{ marginLeft: "15px" }}
            sx={{
              boxShadow: (theme) =>
                `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab
                key={tab.value}
                iconPosition="end"
                value={tab.value}
                label={tab.label}
                icon={
                  <Label
                    variant={
                      ((tab.value === "all" || tab.value === filters.status) &&
                        "filled") ||
                      "soft"
                    }
                    color={getColorForStatus(tab.value)}
                  >
                    {tab.value === "all" && tableData.length}
                    {tab.value === "ACTIVE" &&
                      tableData.filter((user) => user.status === "ACTIVE")
                        .length}

                    {tab.value === "INACTIVE" &&
                      tableData.filter((user) => user.status === "INACTIVE")
                        .length}
                    {tab.value === "SUSPENDED" &&
                      tableData.filter((user) => user.status === "SUSPENDED")
                        .length}
                    {tab.value === "CLOSED" &&
                      tableData.filter((user) => user.status === "CLOSED")
                        .length}
                  </Label>
                }
              />
            ))}
          </Tabs>

          <UserTableToolbar
            filters={filters}
            onFilters={handleFilters}
            //
            roleOptions={_roles}
          />

          {canReset && (
            <UserTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              //
              onResetFilters={handleResetFilters}
              //
              results={dataFiltered.length}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <TableContainer sx={{ position: "relative", overflow: "unset" }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) =>
                table.onSelectAllRows(
                  checked,
                  tableData?.map((row) => row.id)
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={confirm.onTrue}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table
                size={table.dense ? "small" : "medium"}
                sx={{ minWidth: 960 }}
              >
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                  onSelectAllRows={(checked) =>
                    table.onSelectAllRows(
                      checked,
                      tableData?.map((row) => row.id)
                    )
                  }
                />

                <TableBody onClick={handleDrawerToggle}>
                  {dataFiltered
                    .slice(
                      table.page * table.rowsPerPage,
                      table.page * table.rowsPerPage + table.rowsPerPage
                    )
                    .map((row) => (
                      <UserTableRow
                        key={row.id}
                        row={row}
                        selected={table.selected.includes(row.id)}
                        onSelectRow={() => table.onSelectRow(row.id)}
                        deleteshow={false}
                        onDeleteRow={(e) => handleDeleteRow(e, row.id)}
                        onEditRow={() => handleEditRow(row.id)}
                        handleDrawerToggle={handleDrawerToggle}
                        setARow={setARow}
                        tableNames={tableNames}
                        showOptions={false}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(
                      table.page,
                      table.rowsPerPage,
                      tableData.length
                    )}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={dataFiltered.length}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            //
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </Card>
      </Container>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete{" "}
            <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </div>
  );
}

TcTable.propTypes = {
  STATUS_OPTIONS: PropTypes.array,
  TABLE_HEAD: PropTypes.array,
  defaultFilters: PropTypes.object,
  tableNames: PropTypes.array,
};
