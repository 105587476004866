import { useMemo } from "react";
// routes
import { paths } from "src/routes/paths";
// locales
import { useLocales } from "src/locales";
// components
// import Label from 'src/components/label';
import Iconify from "src/components/iconify";
import SvgColor from "src/components/svg-color";
// import { jwtDecode } from "jwt-decode";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon("ic_job"),
  blog: icon("ic_blog"),
  chat: icon("ic_chat"),
  mail: icon("ic_mail"),
  user: icon("ic_user"),
  file: icon("ic_file"),
  lock: icon("ic_lock"),
  tour: icon("ic_tour"),
  order: icon("ic_order"),
  label: icon("ic_label"),
  blank: icon("ic_blank"),
  kanban: icon("ic_kanban"),
  folder: icon("ic_folder"),
  banking: icon("ic_banking"),
  booking: icon("ic_booking"),
  invoice: icon("ic_invoice"),
  product: icon("ic_product"),
  calendar: icon("ic_calendar"),
  disabled: icon("ic_disabled"),
  external: icon("ic_external"),
  menuItem: icon("ic_menu_item"),
  ecommerce: icon("ic_ecommerce"),
  analytics: icon("ic_analytics"),
  dashboard: icon("ic_dashboard"),
  box: <Iconify icon="fluent:box-24-filled" sx={{ width: 24, height: 24 }} />,
  branch: (
    <Iconify icon="fluent:branch-24-filled" sx={{ width: 24, height: 24 }} />
  ),
  pallet: (
    <Iconify
      icon="material-symbols:pallet-outline"
      sx={{ width: 24, height: 24 }}
    />
  ),
  shelf: (
    <Iconify
      icon="material-symbols:shelf-position-sharp"
      sx={{ width: 24, height: 24 }}
    />
  ),
  check: (
    <Iconify icon="pajamas:status-closed" sx={{ width: 24, height: 24 }} />
  ),
  status: (
    <Iconify
      icon="pajamas:status-preparing-borderless"
      sx={{ width: 24, height: 24 }}
    />
  ),
  pick: <Iconify icon="tdesign:undertake" sx={{ width: 24, height: 24 }} />,
  boxToPallet: <Iconify icon="openmoji:move" sx={{ width: 24, height: 24 }} />,
  addToShelf: (
    <Iconify
      icon="material-symbols:orders-outline"
      sx={{ width: 24, height: 24 }}
    />
  ),
  customer: (
    <Iconify icon="material-symbols:person" sx={{ width: 24, height: 24 }} />
  ),
  airwayBill: <Iconify icon="jam:plane" sx={{ width: 24, height: 24 }} />,
  address: <Iconify icon="ic:baseline-place" sx={{ width: 24, height: 24 }} />,
  rs: (
    <Iconify icon="mingcute:government-fill" sx={{ width: 24, height: 24 }} />
  ),
  price: <Iconify icon="solar:tag-price-bold" sx={{ width: 24, height: 24 }} />,
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();
  const auth = JSON.parse(sessionStorage.getItem("token"));

  const managementItems = useMemo(() => {
    const matchingItems = [
      // customer
      {
        title: t("customer"),
        path: paths.dashboard.customer.root,
        icon: ICONS.customer,
      },
      {
        title: t("content"),
        path: paths.dashboard.content.root,
        icon: ICONS.analytics,
      },
      {
        title: t("blogs"),
        path: paths.dashboard.blog.root,
        icon: ICONS.blog,
      },
    ];
    let items = [];
    items = [...matchingItems];
    return items;
    // eslint-disable-next-line
  }, [t, auth]);

  const data = useMemo(
    () => [
      // MANAGEMENT
      {
        subheader: t("Control Panel"),
        items: managementItems,
      },
    ],
    [t, managementItems]
  );

  return data;
}
