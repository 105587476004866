function applyFilter({ inputData = [], comparator, filters }) {
  const { name, status, role, timeSlot, startDate, endDate } = filters;
  const stabilizedThis = inputData?.map((el, index) => [el, index]);
  
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  let filteredData = stabilizedThis?.map((el) => el[0]);

  if (name) {
    filteredData = filteredData.filter(
      (user) =>
        (user.tracking_number &&
          user.tracking_number.trim().toLowerCase().includes(name.toLowerCase())) ||
        (user.tracking_number_2 &&
          user.tracking_number_2.trim().toLowerCase().includes(name.toLowerCase())) ||
        (user.name && user?.name.trim().toLowerCase().includes(name.toLowerCase())) ||
        (user.code && user?.code.trim().toLowerCase().includes(name.toLowerCase())) ||
        (user.room_number && user.room_number.trim().toLowerCase().includes(name.toLowerCase())) ||
        (user.customer &&
          user.customer.room_number.trim().toLowerCase().includes(name.toLowerCase())) ||
        (user.tracking && user.tracking.trim().toLowerCase().includes(name.toLowerCase())) ||
        (user.first_name && user.first_name.trim().toLowerCase().includes(name.toLowerCase())) ||
        (user.last_name && user.last_name.trim().toLowerCase().includes(name.toLowerCase())) ||
        (user.phone && user.phone.trim().toLowerCase().includes(name.toLowerCase())) ||
        (user.id_number && user.id_number.trim().toLowerCase().includes(name.toLowerCase()))
    );
  }

  if (status !== 'all') {
    filteredData = filteredData.filter((user) => user.status === status);
  }

  if (timeSlot !== 'all') {
    filteredData = filteredData.filter((user) => user.timeSlot === timeSlot);
  }

  if (role.length) {
    filteredData = filteredData.filter((user) => role.includes(user.role));
  }

  // Date filtering
  if (startDate && endDate) {
    const start = new Date(startDate).setHours(0, 0, 0, 0);
    const end = new Date(endDate).setHours(23, 59, 59, 999);
    filteredData = filteredData.filter((item) => {
      const itemDate = new Date(item.createdAt).getTime(); // Replace 'createdAt' with your actual date property
      return itemDate >= start && itemDate <= end;
    });
  } else if (startDate) {
    const start = new Date(startDate).setHours(0, 0, 0, 0);
    filteredData = filteredData.filter((item) => {
      const itemDate = new Date(item.createdAt).getTime(); // Adjust for your data's property
      return itemDate >= start;
    });
  } else if (endDate) {
    const end = new Date(endDate).setHours(23, 59, 59, 999);
    filteredData = filteredData.filter((item) => {
      const itemDate = new Date(item.createdAt).getTime(); // Adjust for your data's property
      return itemDate <= end;
    });
  }

  return filteredData;
}
export { applyFilter };
