// BlogContext.js
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import { apiService } from "src/utils/apiServices";
import { toast } from "react-toastify";
import { redirect } from "react-router";

const BlogContext = createContext();
const BlogActionsContext = createContext();
const BlogDetailsContext = createContext();

export const BlogProvider = ({ children }) => {
  const [blogPosts, setBlogPosts] = useState([]);
  // eslint-disable-next-line
  const [uploadedImages, setUploadedImages] = useState([]);

  const createBlogPost = async (newBlogPost) => {
    try {
      const response = await apiService.post("/blog", newBlogPost, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      await response.data;
      getBlogPosts();
      toast.success("Blog post created!");
    } catch (error) {
      toast.error("Error creating blog post:", error);
    }
  };

  const deleteBlogPost = async (blogPostId) => {
    try {
      await apiService.delete(`/blog?ids=${blogPostId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      getBlogPosts();
      toast.success("Blog deleted!");
    } catch (error) {
      console.error("Error deleting blog post:", error.message);
    }
  };

  const updateBlogPost = async (blogPostId, updatedBlogPost) => {
    try {
      const response = await apiService.patch(
        `/blog/${blogPostId}`,
        updatedBlogPost,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      await response.data;
      getBlogPosts();
    } catch (error) {
      toast.error("Error updating blog post:", error);
    }
  };

  const getBlogPosts = async () => {
    try {
      const response = await apiService.get("/blog", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      const data = await response.data;
      setBlogPosts(data);
      return data;
    } catch (error) {
      console.error("Error fetching blog posts:", error);
      if (error.response.status === 401) {
        sessionStorage.removeItem("token");
        redirect("/");
      }
      return error;
    }
  };

  const sendImage = async (imageFile, orderId) => {
    try {
      const formData = new FormData();
      imageFile.forEach((file) => {
        formData.append("file", file);
      });
      const response = await apiService.post(
        `/file/upload/image?blogId=${orderId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      setUploadedImages((prevImages) => [...prevImages, response.data]);
      getBlogPosts();
      toast.success("Image Uploaded!");
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error("Error uploading image!");
    }
  };

  const sendVideoFiles = async (imageFile, orderId) => {
    try {
      const formData = new FormData();
      imageFile.forEach((file) => {
        formData.append("file", file);
      });
      const response = await apiService.post(
        `/file/upload/video?blogId=${orderId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      setUploadedImages((prevImages) => [...prevImages, response.data]);
      getBlogPosts();
      toast.success("Video Uploaded!");
    } catch (error) {
      console.error("Error uploading video:", error);
      toast.error("Error uploading video!");
    }
  };

  useEffect(() => {
    getBlogPosts();
  }, []);

  const createBlogPostCallback = useCallback(createBlogPost, []);
  const deleteBlogPostCallback = useCallback(deleteBlogPost, []);
  const updateBlogPostCallback = useCallback(updateBlogPost, []);
  const getBlogPostsCallback = useCallback(getBlogPosts, []);
  const sendImageCallBack = useCallback(sendImage, []);
  const sendVideoFilesCallBalck = useCallback(sendVideoFiles, []);

  const BlogActionsValue = useMemo(
    () => ({
      createBlogPost: createBlogPostCallback,
      deleteBlogPost: deleteBlogPostCallback,
      updateBlogPost: updateBlogPostCallback,
      getBlogPosts: getBlogPostsCallback,
      sendImage: sendImageCallBack,
      sendVideoFiles: sendVideoFilesCallBalck,
    }),
    [
      createBlogPostCallback,
      deleteBlogPostCallback,
      updateBlogPostCallback,
      getBlogPostsCallback,
      sendImageCallBack,
      sendVideoFilesCallBalck,
    ]
  );

  const BlogItemsValue = useMemo(() => ({ blogPosts }), [blogPosts]);

  return (
    <BlogContext.Provider value={BlogItemsValue}>
      <BlogActionsContext.Provider value={BlogActionsValue}>
        {children}
      </BlogActionsContext.Provider>
    </BlogContext.Provider>
  );
};

export const useBlogData = () => useContext(BlogContext);
export const useBlogActions = () => useContext(BlogActionsContext);
export const useBlogDetails = () => useContext(BlogDetailsContext);

BlogProvider.propTypes = {
  children: PropTypes.element,
};
