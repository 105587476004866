import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import PropTypes from 'prop-types';
import '../../styles/dashboard.scss';

export default function DatePickerValue({ setter, value, selectName }) {
  const selectedDate = (newValue) => {
    setter({ target: { name: selectName, value: newValue.toString() } });
  };
  const formattedDate = value instanceof Date ? value : '';

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker
          label="choose date"
          value={formattedDate}
          onChange={(newValue) => selectedDate(newValue)}
          className="custom-date-picker"
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
DatePickerValue.propTypes = {
  value: PropTypes.instanceOf(Date),
  selectName: PropTypes.string,
  setter: PropTypes.func,
};
