import { Button, Drawer, IconButton, Typography, Avatar } from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { personalInformation } from "./view/data";
import InputFieds from "../../custom_components/Froms/InputFields";
import { useCustomerActions } from "../../context/providers/CustomerContext";
import "../../styles/dashboard.scss";
import CustomizedSwitches from "src/custom_components/Froms/InputSwitcher";
import DatePickerValue from "src/custom_components/Froms/datePicker";

const CustomerDrawerComponent = ({
  isDrawerOpen,
  handleDrawerToggle,
  aRow,
  setARow,
}) => {
  const { updateCustomer } = useCustomerActions();
  const [userData, setUserData] = useState({
    id: aRow.id || "",
    name: aRow?.name || "",
    last_name: aRow.last_name || "",
    phone: aRow.phone || "",
    email: aRow.email || "",
    isAdmin: aRow.isAdmin,
    dateOfBirth: aRow.dateOfBirth || null,
  });

  const DrawerConfig = {
    SubNavigation: false,
    anchor: "right",
    width: "40%",
    drawerHeaderText: "Manage Customer Information",
    SubNav: true,
  };

  const containerStyle = {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    position: "fixed",
    zIndex: 2,
    top: 0,
    left: 0,
    display: isDrawerOpen ? "block" : "none",
    cursor: "pointer",
  };

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      setARow({});
      handleDrawerToggle();
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const UpdateCustomerData = () => {
    const customerId = aRow.id;
    const updatedCustomerData = {
      id: customerId,
      name: userData?.name,
      last_name: userData.last_name,
      phone: userData.phone,
      email: userData.email,
      isAdmin: userData.isAdmin,
      dateOfBirth: userData.dateOfBirth,
    };
    updateCustomer(customerId, updatedCustomerData);
    handleOutsideClick();
  };
  return (
    <div
      role="button"
      tabIndex={0}
      style={containerStyle}
      onKeyDown={(event) => {
        if (event.key === "Escape") {
          setARow({});
          handleDrawerToggle();
        }
      }}
      onClick={handleOutsideClick}
    >
      <Drawer
        variant="persistent"
        anchor={DrawerConfig.anchor}
        open={isDrawerOpen}
        onClose={() => {
          setARow({});
          handleDrawerToggle();
        }}
        PaperProps={{
          sx: { width: { lg: DrawerConfig.width, xs: "100%", md: "100%" } },
        }}
      >
        {/* Drawer Content */}

        {/* Header */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px",
            borderBottom: "1px solid rgba(220, 220, 220, 0.2)",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Avatar
              sx={{
                bgcolor: "#DFE3E8",
                color: "#637381",
                borderRadius: "10px",
                width: "32px",
                height: "32px",
                fontSize: "16px",
              }}
              variant="square"
            >
              {userData?.name[0]?.toUpperCase()}
            </Avatar>
            <Typography variant="h6">
              {userData?.first_name} {userData?.last_name}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <IconButton
              onClick={() => {
                handleDrawerToggle();
                setARow({});
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        <div
          style={{
            minHeight: "90vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <InputFieds
              userData={userData}
              heading="Personal Information"
              inputFields={personalInformation}
              setUserData={setUserData}
            />
            {/* InputSwitcher */}
            <div
              style={{
                padding: "0px 0px 0px 20px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <DatePickerValue
                setter={handleInputChange}
                value={new Date(userData.dateOfBirth)}
                selectName="Date Of Birth"
              />
              <CustomizedSwitches
                text="isAdmin"
                widthComp="96%"
                isChecked={userData.isAdmin}
                userData={userData}
                setUserData={setUserData}
              />
            </div>
            {/* InputSwitcher */}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              borderTop: "1px solid rgba(220, 220, 220, 0.2)",
              padding: "30px",
            }}
          >
            <Button variant="contained" onClick={() => UpdateCustomerData()}>
              Update
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

CustomerDrawerComponent.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  aRow: PropTypes.object.isRequired,
  setARow: PropTypes.func.isRequired,
};

export default CustomerDrawerComponent;
