// ContentContext.js
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import { apiService } from "src/utils/apiServices";
import { toast } from "react-toastify";
import { redirect } from "react-router";

const ContentContext = createContext();
const ContentActionsContext = createContext();
const ContentDetailsContext = createContext();

export const ContentProvider = ({ children }) => {
  const [contentItems, setContentItems] = useState([]);
  // const [contentItemsUnique, setContentItemsUnique] = useState([]);
  // const [contentImages, setContentImages] = useState([]);
  // const [selectedContent, setSelectedContent] = useState(null);

  const createContentItem = async (newContentItem) => {
    try {
      const response = await apiService.post("/content", newContentItem, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      // eslint-disable-next-line
      const data = await response.data;
      getContentItems();
      toast.success("Content created!");
    } catch (error) {
      toast.error("Error creating content item:", error);
    }
  };

  const createNewRoles = async (newRoleItem) => {
    try {
      const response = await apiService.post("/admin/signup", newRoleItem, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      // eslint-disable-next-line
      const data = await response.data;
      getContentItems();
      toast.success("Role created!");
    } catch (error) {
      toast.error("Error creating new role:", error.message);
    }
  };

  const deleteContentItem = async (contentItemId) => {
    try {
      await apiService.delete(`/content-management/${contentItemId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      setContentItems((prevItems) =>
        prevItems.filter((item) => !contentItemId.includes(item.id))
      );
    } catch (error) {
      console.error("Error deleting content item:", error.message);
    }
  };

  const updateContentItem = async (contentItemId, updatedContentItem) => {
    try {
      const response = await apiService.patch(
        `/content/${contentItemId}`,
        updatedContentItem,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.data;
      setContentItems((prevItems) =>
        prevItems.map((item) =>
          item.id === contentItemId ? { ...item, ...data } : item
        )
      );
    } catch (error) {
      toast.error("Error updating content item:", error);
    }
  };

  const updateVersionItem = async (contentItemId, version) => {
    try {
      const response = await apiService.post(
        `/content/revert/${contentItemId}`,
        {
          version: version,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.data;
      setContentItems((prevItems) =>
        prevItems.map((item) =>
          item.id === contentItemId ? { ...item, ...data } : item
        )
      );
    } catch (error) {
      toast.error("Error updating content item:", error);
    }
  };

  const getContentItems = async () => {
    try {
      const response = await apiService.get("/content", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      const data = await response.data;
      setContentItems(data);
      return data;
    } catch (error) {
      console.error("Error fetching content items:", error);
      if (error.response.status === 401) {
        sessionStorage.removeItem("token");
        redirect("/");
      }
      return error;
    }
  };
  // const getUniqueItems = async () => {
  //   try {
  //     const response = await apiService.get('/content-management/uniques', {
  //       headers: {
  //         Authorization: `Bearer ${sessionStorage.getItem('token')}`,
  //       },
  //     });
  //     const data = await response.data;
  //     setContentItemsUnique(data);
  //     return data;
  //   } catch (error) {
  //     console.error('Error fetching content items:', error);
  //     return error;
  //   }
  // };
  // const getContentItemById = async (contentItemId) => {
  //   try {
  //     const response = await apiService.get(`/content-management/${contentItemId}`, {
  //       headers: {
  //         Authorization: `Bearer ${sessionStorage.getItem('token')}`,
  //       },
  //     });
  //     const data = await response.data;
  //     setSelectedContent(data);
  //     return data;
  //   } catch (error) {
  //     console.error('Error fetching content item by ID:', error);
  //     throw error;
  //   }
  // };

  // const sendContentImage = async (imageFile, data) => {
  //   try {
  //     const formData = new FormData();
  //     imageFile.forEach((file) => {
  //       formData.append('file', file);
  //     });
  //     // eslint-disable-next-line
  //     const response = await apiService.post(`/s3/Content/image/upload`, formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //       params: {
  //         data: JSON.stringify(data),
  //       },
  //     });
  //   } catch (error) {
  //     toast.error('Error updating content item:', error);
  //   }
  // };

  // const getContextImages = async () => {
  //   try {
  //     const response = await apiService.get('/content-management-img', {
  //       headers: {
  //         Authorization: `Bearer ${sessionStorage.getItem('token')}`,
  //       },
  //     });
  //     const data = await response.data;
  //     setContentImages(data);
  //     return data;
  //   } catch (error) {
  //     return error;
  //   }
  // };

  // const sendContentImageUpdate = async (imageFile, data) => {
  //   try {
  //     const formData = new FormData();
  //     imageFile.forEach((file) => {
  //       formData.append('file', file);
  //     });
  //     // eslint-disable-next-line
  //     const response = await apiService.post(`/s3/Content/image/update`, formData, {
  //       headers: {
  //         Authorization: `Bearer ${sessionStorage.getItem('token')}`,
  //       },
  //       params: {
  //         data: JSON.stringify(data),
  //       },
  //     });
  //     getContentItems();
  //     getUniqueItems();
  //     getContextImages();
  //   } catch (error) {
  //     toast.error('Could not update');
  //   }
  // };

  useEffect(() => {
    getContentItems();
    // getUniqueItems();
    // getContextImages();
  }, []);

  const createContentItemCallback = useCallback(createContentItem, []);
  const createNewRolesCallback = useCallback(createNewRoles, []);
  const deleteContentItemCallback = useCallback(deleteContentItem, []);
  const updateContentItemCallback = useCallback(updateContentItem, []);
  const updateVersionItemCallback = useCallback(updateVersionItem, []);
  const getContentItemsCallback = useCallback(getContentItems, []);
  // const getUniqueItemsCallback = useCallback(getUniqueItems, []);
  // const sendContentImageCallback = useCallback(sendContentImage, []);
  // const getContextImagesCallback = useCallback(getContextImages, []);
  // const sendContentImageUpdateCallback = useCallback(sendContentImageUpdate, []);

  const ContentActionsValue = useMemo(
    () => ({
      createContentItem: createContentItemCallback,
      deleteContentItem: deleteContentItemCallback,
      updateContentItem: updateContentItemCallback,
      getContentItems: getContentItemsCallback,
      updateVersionItem: updateVersionItemCallback,
      createNewRoles: createNewRolesCallback,
    }),
    [
      createContentItemCallback,
      deleteContentItemCallback,
      updateVersionItemCallback,
      getContentItemsCallback,
      updateContentItemCallback,
      createNewRolesCallback,
    ]
  );

  const ContentItemsValue = useMemo(
    () => ({
      contentItems,
      // contentItemsUnique,
      // contentImages,
    }),
    [contentItems]
  );

  return (
    <ContentContext.Provider value={ContentItemsValue}>
      <ContentActionsContext.Provider value={ContentActionsValue}>
        {/* <ContentDetailsContext.Provider value={selectedContent}> */}
        {children}
        {/* </ContentDetailsContext.Provider> */}
      </ContentActionsContext.Provider>
    </ContentContext.Provider>
  );
};

export const useContentData = () => useContext(ContentContext);
export const useContentActions = () => useContext(ContentActionsContext);
export const useContentDetails = () => useContext(ContentDetailsContext);

ContentProvider.propTypes = {
  children: PropTypes.element,
};
