const getColorForStatus = (stat) => {
  switch (stat) {
    case 'ACTIVE':
    case 'PLACED':
      return 'success';
    case 'PROCESSING':
    case 'INACTIVE':
    case 'Awaiting':
    case 'Received in US warehouse':
    case 'Hold in US warehouse':
    case 'On the way from US warehouse':
    case 'Droped in US airport facility':
    case 'Air Shipped from US':
    case 'Customs Clearance':
    case 'Customs Expertise':
    case 'Received in Tbilisi Warehouse':
    case 'Out For Delivery':
    case 'Failed Delivery Attempt':
    case 'Delivered':
      return 'default';
    case 'SUSPENDED':
    case 'SHIPPED':
      return 'warning';
    case 'CLOSED':
    case 'OUT_FOR_DELIVERY':
    case 'Canceled':
    case 'Unclear Order':
    case 'Action Needed':
    case 'Needs Custom Clearance':
    case 'Deadline for the customs clearance completion':
    case 'Needs to be transferred to government':
    case 'Transferred to government':
    case 'Lost Parcel':
      return 'error';
    default:
      return 'info';
  }
};

export { getColorForStatus };
