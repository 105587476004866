import PropTypes from "prop-types";
import { useState, useCallback } from "react";
// import '../../styles/dashboard.scss';
// @mui
// import { useOrderData } from 'src/context/providers/OrderContext';
import Image from "src/components/image";
import { alpha } from "@mui/material/styles";
import { Stack, IconButton } from "@mui/material";
import { MultiFilePreview, UploadBox } from "src/components/upload";
import Iconify from "../../components/iconify";
import { API_BASE_URL } from "src/utils/apiServices";

// ----------------------------------------------------------------------
export default function UploadImage({
  disabled,
  row,
  files,
  setFiles,
  deletedImages,
  setDeletedImages,
  hasPadding = true,
}) {
  const [zoomedImages, setZoomedImages] = useState([]);

  // const tableData = useOrderData().orders.filter((i) => i.id === row?.id)[0];
  const [displayedImages, setDisplayedImages] = useState([]);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFiles([...files, ...newFiles]);
    },
    [files, setFiles]
  );
  const handleRemoveFile = useCallback(
    (inputFile) => {
      const filtered = files.filter((file) => file !== inputFile);
      setFiles(filtered);
    },
    [files, setFiles]
  );

  const toggleZoom = (imageId) => {
    setZoomedImages((prevZoomedImages) => {
      if (prevZoomedImages.includes(imageId)) {
        return prevZoomedImages.filter((id) => id !== imageId);
      }
      return [...prevZoomedImages, imageId];
    });
  };
  const removeImage = (imageId) => {
    const deletedImage = displayedImages.find((i) => i.id === imageId);
    setDeletedImages((prevDeletedImages) => [
      ...prevDeletedImages,
      deletedImage,
    ]);
    setDisplayedImages((prevImages) =>
      prevImages.filter((i) => i.id !== imageId)
    );
  };
  const bearerToken = sessionStorage.getItem("token");

  return (
    <div
      className="content-wrap"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "2px",
        padding: hasPadding ? "30px 0px 0px 20px" : "",
      }}
    >
      {/* <Typography gutterBottom className="subTitles">
        Upload image
      </Typography> */}

      <div
        className="content-wrap"
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
        }}
      >
        <Stack direction="row" flexWrap="wrap">
          <UploadBox onDrop={handleDrop} sx={{ width: 100, height: 100 }} />
          <MultiFilePreview
            thumbnail
            files={files}
            onRemove={(file) => handleRemoveFile(file)}
            sx={{ width: 100, height: 100 }}
          />
          {displayedImages?.map((i) => (
            <div key={i.id} style={{ position: "relative" }}>
              <Image
                key={i.id}
                alt={i.name}
                src={`${API_BASE_URL}/s3/image/${i.name}?token=${bearerToken}`}
                ratio="1/1"
                disabledEffect
                sx={{
                  borderRadius: 1.25,
                  m: 0.5,
                  width: 100,
                  height: 100,
                }}
                className={zoomedImages.includes(i.id) ? "zoom-on-hover" : ""}
                onMouseLeave={() => setZoomedImages("")} // Clear zoom on mouse out
              />
              <IconButton
                size="small"
                onClick={() => toggleZoom(i.id)}
                sx={{
                  p: 0.5,
                  top: 80,
                  right: 8,
                  position: "absolute",
                  color: "common.white",
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                  "&:hover": {
                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                  },
                }}
              >
                <Iconify icon="material-symbols:pan-zoom" width={14} />
              </IconButton>

              <IconButton
                size="small"
                onClick={() => removeImage(i.id)}
                sx={{
                  p: 0.5,
                  top: 10,
                  right: 8,
                  position: "absolute",
                  color: "common.white",
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                  "&:hover": {
                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                  },
                }}
              >
                <Iconify icon="mingcute:close-line" width={14} />
              </IconButton>
            </div>
          ))}
        </Stack>
      </div>
    </div>
  );
}

UploadImage.propTypes = {
  disabled: PropTypes.object,
  row: PropTypes.object,
  files: PropTypes.array,
  setFiles: PropTypes.func,
  deletedImages: PropTypes.array,
  setDeletedImages: PropTypes.func,
  hasPadding: PropTypes.bool,
};
