import * as React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
export default function CustomizedSwitches({
  text,
  isChecked,
  userData,
  setUserData,
  widthComp = "46%",
  disabledForm = false,
}) {
  const handleToggle = () => {
    setUserData((prevState) => ({
      ...prevState,
      [text]: !isChecked,
    }));
  };
  return (
    <FormGroup
      style={{
        boxSizing: "border-box",
        border: "2px solid rgba(145, 158, 171, 0.16)",
        width: widthComp,
        padding: "10px",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography
        gutterBottom
        className="subTitles"
        style={{ paddingTop: "10px", textAlign: "left" }}
      >
        {text}
      </Typography>
      <FormControlLabel
        control={
          <IOSSwitch
            checked={Boolean(isChecked)}
            disabled={disabledForm}
            onChange={handleToggle}
          />
        }
      />
    </FormGroup>
  );
}
CustomizedSwitches.propTypes = {
  text: PropTypes.string,
  isChecked: PropTypes.bool,
  userData: PropTypes.object,
  setUserData: PropTypes.func,
  widthComp: PropTypes.string,
  disabledForm: PropTypes.bool,
};
