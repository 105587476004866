// @mui
import { TableRow, Checkbox, TableCell } from "@mui/material";

// hooks
// props
import PropTypes from "prop-types";

// components
import Label from "src/components/label";
import { getColorForStatus } from "./helper/getColorStatus";

// ----------------------------------------------------------------------
export default function UserTableRow({
  row,
  selected,
  onSelectRow,
  onDeleteRow,
  tableNames,
  handleDrawerToggle,
  setARow,
  deleteshow = true,
  ignoreCountry = false,
  showOptions = true,
}) {
  const chekBoxHandler = (e) => {
    e.stopPropagation();
    onSelectRow(e);
  };

  const tableConfig = {
    showCheckBox: true,
  };

  function renderTable() {
    return tableNames.map((i, index) => {
      switch (i.id) {
        case "isAdmin":
          return (
            <TableCell key={index}>
              <Label variant="soft" color={getColorForStatus(row.status)}>
                {row.isAdmin === true ? "YES" : "NO"}
              </Label>
            </TableCell>
          );
        case "translations":
          return (
            <TableCell key={index}>
              {Object.keys(row.translations).map((key, index) => (
                <>
                  <Label
                    variant="soft"
                    key={index}
                    sx={{ mb: 1 }}
                    color="success"
                  >
                    {key} : {row.translations[key]}
                  </Label>
                  <br />
                </>
              ))}
            </TableCell>
          );
        default:
          return (
            <TableCell sx={{ whiteSpace: "nowrap" }} key={i.id}>
              {row[i.id]}
            </TableCell>
          );
      }
    });
  }
  return (
    <>
      <TableRow
        hover
        selected={selected}
        sx={{ cursor: "pointer" }}
        onClick={() => {
          handleDrawerToggle();
          setARow(row);
        }}
      >
        {tableConfig.showCheckBox && (
          <TableCell padding="checkbox">
            <Checkbox checked={selected} onClick={chekBoxHandler} />
          </TableCell>
        )}

        {renderTable()}
      </TableRow>
    </>
  );
}

UserTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
  tableNames: PropTypes.array,
  handleDrawerToggle: PropTypes.func,
  setARow: PropTypes.func,
  deleteshow: PropTypes.bool,
  ignoreCountry: PropTypes.bool,
  showOptions: PropTypes.bool,
};
